import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { COUNTRY } from '../../configs/env';
import { WpFeedDataPropsI, WpPostNodeI } from '../../types/WpFeedData';
import { getFeaturedImgForBlogLayout } from '../../helpers/getFeaturedImgForBlogLayout';
import { BlogFeedPage } from '../blog/new-blog-feed.page';
import { BlogPostI } from '../blog/BlogPost.interface';

const PromoPage = (props: WpFeedDataPropsI): React.FC | ReactElement => {
  const { t } = useTranslation();
  const meta = {
    title: t('meta_PromoTitle'),
    description: t('meta_PromoDescription'),
  };

  const returnWpPostsByCountry = (edge: WpPostNodeI) => {
    if (COUNTRY === 'rs') {
      return edge.node.country === COUNTRY || !edge.node.country;
    } else {
      return edge.node.country === COUNTRY;
    }
  };

  const posts: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter((edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'promo').length > 0)
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .map((edge: WpPostNodeI) => {
      return {
        id: edge.node.id,
        title: edge.node.title,
        excerpt: edge.node.excerpt,
        featuredImg: getFeaturedImgForBlogLayout(edge.node),
        date: edge.node.date,
        url: `/sr/promo/${edge.node.slug}`,
        categories: edge.node.categories,
        tags: edge.node.tags,
      };
    });

  return (
    <BlogFeedPage
      type="promo"
      posts={posts}
      meta={meta}
      featuredPostsTag="promo_campaign_active"
      featuredPostsTitle="Najnovije kampanje:"
    />
  );
};

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          tags {
            name
          }
          slug
          path
          title
          excerpt
          status
          date
          country
          template
          format
          categories {
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PromoPage;
